import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import wireswap from "./assets/wireswap_main.jpg"

function Wireswap() {
  return (
    <div className="sEeCase">
      <div className="container-fluid">
        <div className="row CaSeeUproW">
          <div className="col-lg-12 px-0">
            <div className="titles">
              <h1 className="caseHeading">Wireswap Protocol</h1>
              <h1 className="caseHeading">Guild QB [Quest & Battle]</h1>
            </div>

            <div className="media">
              <img src={wireswap} alt="" className="w-100" />
            </div>
          </div>
        </div>
        <div className="row CaSeeUproW2">
          <div className="col-lg-5">
            <div className="LeFtTitlE">
              <h6>Overview</h6>
            </div>
          </div>

          <div className="col-lg-7 rtSdd">
            <h2 className="detail2TiTlE">
              Wireswap Protocol <br />
            </h2>

            <div className="mt-4">
              <p className="detAilPeRa">
                Wireswap aims to be a cross-chain decentralized exchange aiming
                to bring lightspeed swaps at the touch of a button. <br />
                Wireswap is a multi-chain protocol that aims to facilitate swaps
                between more than 10,000 tokens on different blockchains such as
                Ethereum, Binance Smart chain, Solana, Polygon, Fantom,
                Avalanche, Moonriver in 1 touch. $WIRE is a governance token for
                Wireswap protocol, all fees and internal accounting will be in
                $WIRE. Users will need $WIRE to execute swaps between different
                chains.
              </p>
            </div>

            <div className="row servs">
              <div className="col-lg-4 liuPrr">
                <h6 className="clName">Client</h6>
                <p className="nme">Punk Panda</p>
              </div>
              <div className="col-lg-4 liuPrr fstUl">
                <h6 className="clName">Services</h6>
                <ul className="uppl">
                  <li className="uppli">Blockchain</li>
                  <li className="uppli">UI design</li>
                  <li className="uppli">Development</li>
                </ul>
              </div>
              <div className="col-lg-4 liuPrr fstUl">
                <h6 className="clName">Technologies</h6>
                <ul className="uppl">
                  <li className="uppli">React.js</li>
                  <li className="uppli">Node.js</li>
                  <li className="uppli">Solidity</li>
                </ul>
              </div>
            </div>

            <div className="liveew">
              <a href="https://wireswap.io/" target="blank" className="text-decoration-none">
                <button className="btn seELivE_butn">
                  <FiArrowUpRight className="fs-5" />
                  See live
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* <div className="row CaSeeUproW3">
          <div className="col-lg-6">
            <div className="dBordPic">
              <img
                src={qbb3rd}
                alt="#"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-lg-6 rtSdd2">
            <div className="dBordPic">
              <img
                src={qbb4th}
                alt="#"
                className="w-100"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Wireswap;
