import React from 'react'
import First_sec from './First_sec/First_sec'
import GetA from './GetA/GetA'
import SeeSlider from './SeeSlider/SeeSlider'
import Technology_Stack from './Technology_Stack/Technology_Stack'
import Chat from "./Chat"
import GetEmail from './GetEmail/GetEmail'

function Main_home() {
  return (
    <div>
      <First_sec/>
      {/* <GetEmail/> */}
      <GetA/>
      <SeeSlider/>
      <Technology_Stack/>
      <Chat/>
    </div>
  )
}

export default Main_home
