import React from 'react'
import "./TechnologyStack_type.css"

function Token() {
  return (
    <div>
        <div className="row justify-content-evenly " style={{marginTop:"4rem"}}>
      <div className="col-lg-6">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/ethereum-icon-purple-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>ERC-20</h5>
        </div>
      </div>

      <div className="col-lg-6 meg2">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/ethereum-icon-purple-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>ERC-1155</h5>
        </div>
      </div>

      <div className="col-lg-6 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/binance-logo-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>BEP-20</h5>
        </div>
      </div>

      <div className="col-lg-6 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/ethereum-icon-purple-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>ERC-721</h5>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Token
