import React from "react";
import "./Case_detail.css";
import { FiArrowUpRight } from "react-icons/fi";
import qbmainpic from "../assets/quest_main.jpg";

function Case_detail() {
  return (
    <div className="sEeCase">
      <div className="container-fluid">
        <div className="row CaSeeUproW">
          <div className="col-lg-12 px-0">
            <div className="titles">
              <h1 className="caseHeading">Blockchain Gaming </h1>
              <h1 className="caseHeading">Join QB Quest!</h1>
              <h1 className="caseHeading">
                Refer a friend <br /> and earn rewards!
              </h1>
            </div>

            <div className="media">
              <img src={qbmainpic} alt="" className="w-100" />
            </div>
          </div>
        </div>
        <div className="row CaSeeUproW2">
          <div className="col-lg-5">
            <div className="LeFtTitlE">
              <h6>Overview</h6>
            </div>
          </div>

          <div className="col-lg-7 rtSdd">
            <h2 className="detail2TiTlE">
              Blockchain Gaming <br /> Join QB Quest! <br /> You’ll both earn
              Discord Supporter Role! And more for you to explore.
            </h2>

            <div className="mt-4">
              <p className="detAilPeRa">
                Get exclusive GuildQB rewards for referrals! Earn 1 point for
                every friend who installs GuildQB and your friend will get 1
                point too. Get different kinds of rewards with your points.
              </p>
            </div>

            <div className="row servs">
              <div className="col-lg-4 liuPrr">
                <h6 className="clName">Client</h6>
                <p className="nme">Punk Panda</p>
              </div>
              <div className="col-lg-4 liuPrr fstUl">
                <h6 className="clName">Services</h6>
                <ul className="uppl">
                  <li className="uppli">Blockchain</li>
                  <li className="uppli">UI design</li>
                  <li className="uppli">Development</li>
                </ul>
              </div>
              <div className="col-lg-4 liuPrr fstUl">
                <h6 className="clName">Technologies</h6>
                <ul className="uppl">
                  <li className="uppli">React.js</li>
                  <li className="uppli">Node.js</li>
                  <li className="uppli">Solidity</li>
                </ul>
              </div>
            </div>

            <div className="liveew">
              <a
                href="https://quest.guildqb.com/"
                target="blank"
                className="text-decoration-none"
              >
                <button className="btn seELivE_butn">
                  <FiArrowUpRight className="fs-5" />
                  See live
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* <div className="row CaSeeUproW3">
          <div className="col-lg-6">
            <div className="dBordPic">
              <img
                src={qbb3rd}
                alt="#"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-lg-6 rtSdd2">
            <div className="dBordPic">
              <img
                src={qbb4th}
                alt="#"
                className="w-100"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Case_detail;
