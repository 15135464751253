import React from 'react'
import "./TechnologyStack_type.css"

function SmartContrect() {
  return (
    <div>
        <div className="row justify-content-evenly " style={{marginTop:"4rem"}}>
      <div className="col-lg-5">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/solidity-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Solidity</h5>
        </div>
      </div>

      <div className="col-lg-5 meg2">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/image-removebg-preview-1.png" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Remix</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/webp-express/webp-images/uploads/2023/05/image-removebg-preview-1-1.png.webp" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Solc</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/Truffle-Suite-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Truffle</h5>
        </div>
      </div>
      </div>
    </div>
  )
}

export default SmartContrect
