import React, { useEffect } from 'react'

export default function KommunicateChat() {
    useEffect(()=>{
        (function(d, m){
            var kommunicateSettings = {"appId":"24dd83ec2b12735a19f0e10fb5f5a7eec","popupWidget":true,"automaticChatOpenOnNavigation":true};
            // var s = document.createElement("script"); s.type = "text/javascript"; s.async = true;
            // s.src = "https://widget.kommunicate.io/v2/kommunicate.app";
            // var h = document.getElementsByTagName("head")[0]; h.appendChild(s);
            window.kommunicate = m; m._globals = kommunicateSettings;
          })(document, window.kommunicate || {});

    },[])
  return (
    <div>

    </div>
  )
}
