import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import arccoin from "./assets/archie_stake_main.jpg";

function ArccoinARC() {
  return (
    <div className="sEeCase">
      <div className="container-fluid">
        <div className="row CaSeeUproW">
          <div className="col-lg-12 px-0">
            <div className="titles">
              <h1 className="caseHeading">Arccoin(ARC)</h1>
            </div>

            <div className="media">
              <img src={arccoin} alt="" className="w-100" />
            </div>
          </div>
        </div>
        <div className="row CaSeeUproW2">
          <div className="col-lg-5">
            <div className="LeFtTitlE">
              <h6>Overview</h6>
            </div>
          </div>

          <div className="col-lg-7 rtSdd">
            <h2 className="detail2TiTlE">Arccoin(ARC)</h2>

            <div className="mt-4">
              <p className="detAilPeRa">
                ArchieNeko NFT We are creating an NFT collection called
                “ArchieMeta NFT”. As you all requested, it will be based on ETH
                and be available to mint. <br />
                Our project was created with our holder in mind and we have done
                everything in our ability to keep that word and yet volatile
                pricing in the crypto market caused our chart a lot. We have
                decided as a community to create our own Layer 1 Block Chain and
                support upcoming Metaverse (ARCHIE META) project. The need
                exists to change the current crypto space where the value of
                your project is only tied to hype and buying or selling the
                coin/token.
              </p>
            </div>

            <div className="row servs">
              <div className="col-lg-4 liuPrr">
                <h6 className="clName">Client</h6>
                <p className="nme">Punk Panda</p>
              </div>
              <div className="col-lg-4 liuPrr fstUl">
                <h6 className="clName">Services</h6>
                <ul className="uppl">
                  <li className="uppli">Blockchain</li>
                  <li className="uppli">UI design</li>
                  <li className="uppli">Development</li>
                </ul>
              </div>
              <div className="col-lg-4 liuPrr fstUl">
                <h6 className="clName">Technologies</h6>
                <ul className="uppl">
                  <li className="uppli">React.js</li>
                  <li className="uppli">Node.js</li>
                  <li className="uppli">Solidity</li>
                </ul>
              </div>
            </div>

            <div className="liveew">
              <a
                href="https://staking.archiecoin.io/"
                target="blank"
                className="text-decoration-none"
              >
                <button className="btn seELivE_butn">
                  <FiArrowUpRight className="fs-5" />
                  See live
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArccoinARC;
