import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import one from "../assets/quest.jpg";
import b from "../assets/archieswap.jpg";
import c from "../assets/bookofgat2.jpg";
import d from "../assets/archie_stake.jpg";
import e from "../assets/sanit.jpg";
import sixth from "../assets/gulidqb.jpg";
import siven from "../assets/main_wireswap.jpg";
import eight from "../assets/nftxpressNew.jpg";
import nine from "../assets/archiscan.jpg";
import ten from "../assets/archieneko.jpg";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { GoArrowUpRight } from "react-icons/go";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./SeeSlider.css";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function SeeSlider() {
  const history = useNavigate();
  return (
    <>
      <div className="just">
        <div className="container py-5">
          <div className="section-heading text-start my-5 d-flex justify-content-between">
            <h1>
              {" "}
              <b className="">
                {" "}
                See how <span className=" textcolor"> engaging </span>{" "}
                <br className="d-none d-md-block" />
                <span className=""> it could be </span>{" "}
              </b>{" "}
            </h1>
          </div>
          <Swiper
            slidesPerView={3}
            spaceBetween={10}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              // 640: {
              //   slidesPerView: 1,
              //   spaceBetween: 20,
              // },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide className="boost">
              <>
                <div className="maindasda">
                  <div className="mainimg det2" onClick={() => (
                        history("/Case_detail"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                    <img src={one} className="slidimg" alt="" />
                  </div>
                  <div className="a ">
                    <h3 className="text-start  just pt-5 m-0">
                      <span className="det2" onClick={() => (
                        history("/Case_detail"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                      {" "}
                      Blockchain Gaming <br /> Join QB Quest!
                      </span>
                    </h3>
                  </div>
                  <div className="just d-flex ">
                    <button
                      className="btt seecase"
                      onClick={() => (
                        history("/Case_detail"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}
                    >
                      <GoArrowUpRight /> See case{" "}
                    </button>
                  </div>
                </div>
              </>
            </SwiperSlide>

            <SwiperSlide className="boost">
              <>
                <div className="maindasda">
                  <div className="mainimg det2" onClick={() => (
                        history("/Case_detail2"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                    <img src={b} className="slidimg" alt="" />
                  </div>
                  <div className="a ">
                    <h3 className="text-start  just pt-5 m-0">
                      <span className="det2" onClick={() => (
                        history("/Case_detail2"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                      ARCHIESWAP
                      </span>
                    </h3>
                  </div>
                  <div className="just d-flex ">
                    <button
                      className="btt seecase"
                      onClick={() => (
                        history("/Case_detail2"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}
                    >
                      <GoArrowUpRight /> See case{" "}
                    </button>
                  </div>
                </div>
              </>
            </SwiperSlide>

            <SwiperSlide className="boost">
              <>
                <div className="maindasda">
                  <div className="mainimg det2" onClick={() => (
                        history("/Case_detail3"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                    <img src={c} className="slidimg" alt="" />
                  </div>
                  <div className="a ">
                    <h3 className="text-start  just  m-0">
                      <h3 className="text-start just pt-5 m-0">
                        <span className="det2" onClick={() => (
                        history("/Case_detail3"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                        Book of Gates NFT
                        </span>
                      </h3>{" "}
                    </h3>
                  </div>
                  <div className="just d-flex ">
                    <button className="btt seecase" onClick={() => (
                  history("/Case_detail3"),
                  window.scrollTo({ top: 0, behavior: "instant" })
                )}>
                      <GoArrowUpRight /> See case{" "}
                    </button>
                  </div>
                </div>
              </>
            </SwiperSlide>

            <SwiperSlide className="boost">
              <>
                <div className="maindasda">
                  <div className="mainimg det2" onClick={() => (
                        history("/ArccoinARC"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                    <img src={d} className="slidimg" alt="" />
                  </div>
                  <div className="a ">
                    <h3 className="text-start  just  m-0">
                      <h3 className="text-start just pt-5 m-0">
                        <span className="det2" onClick={() => (
                        history("/ArccoinARC"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                        Arccoin(ARC){" "}
                        </span>
                      </h3>{" "}
                    </h3>
                  </div>
                  <div className="just d-flex ">
                    <button className="btt seecase" onClick={() => (
                  history("/ArccoinARC"),
                  window.scrollTo({ top: 0, behavior: "instant" })
                )}>
                      <GoArrowUpRight /> See case{" "}
                    </button>
                  </div>
                </div>
              </>
            </SwiperSlide>

            <SwiperSlide className="boost">
              <>
                <div className="maindasda">
                  <div className="mainimg det2" onClick={() => (
                        history("/Sainft"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                    <img src={e} className="slidimg" alt="" />
                  </div>
                  <div className="a ">
                    <h3 className="text-start  just  m-0">
                      <h3 className="text-start just pt-5 m-0">
                        <span className="det2" onClick={() => (
                        history("/Sainft"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                        Sainft
                        </span>
                      </h3>{" "}
                    </h3>
                  </div>
                  <div className="just d-flex ">
                    <button className="btt seecase" onClick={() => (
                  history("/Sainft"),
                  window.scrollTo({ top: 0, behavior: "instant" })
                )}>
                      <GoArrowUpRight /> See case{" "}
                    </button>
                  </div>
                </div>
              </>
            </SwiperSlide>

            <SwiperSlide className="boost">
              <>
                <div className="maindasda">
                  <div className="mainimg det2" onClick={() => (
                        history("/GuildQbQuest"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                    <img src={sixth} className="slidimg" alt="" />
                  </div>
                  <div className="a ">
                    <h3 className="text-start  just pt-5 m-0">
                      {" "}
                      <span className="det2" onClick={() => (
                        history("/GuildQbQuest"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                      Blockchain Gaming <br /> Guild QB [Quest & Battle]
                      </span>
                    </h3>
                  </div>
                  <div className="just d-flex ">
                    <button className="btt seecase" onClick={() => (
                  history("/GuildQbQuest"),
                  window.scrollTo({ top: 0, behavior: "instant" })
                )}>
                      <GoArrowUpRight /> See case{" "}
                    </button>
                  </div>
                </div>
              </>
            </SwiperSlide>

            <SwiperSlide className="boost">
              <>
                <div className="maindasda">
                  <div className="mainimg det2" onClick={() => (
                        history("/Wireswap"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                    <img src={siven} className="slidimg" alt="" />
                  </div>
                  <div className="a ">
                    <h3 className="text-start  just pt-5 m-0">
                      {" "}
                      <span className="det2" onClick={() => (
                        history("/Wireswap"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                      Wireswap Protocol
                      </span>
                    </h3>
                  </div>
                  <div className="just d-flex ">
                    <button className="btt seecase" onClick={() => (
                  history("/Wireswap"),
                  window.scrollTo({ top: 0, behavior: "instant" })
                )}>
                      <GoArrowUpRight /> See case{" "}
                    </button>
                  </div>
                </div>
              </>
            </SwiperSlide>

            <SwiperSlide className="boost">
              <>
                <div className="maindasda">
                  <div className="mainimg det2" onClick={() => (
                        history("/Nftxpress"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                    <img src={eight} className="slidimg" alt="" />
                  </div>
                  <div className="a ">
                    <h3 className="text-start  just pt-5 m-0">
                      {" "}
                      <span className="det2" onClick={() => (
                        history("/Nftxpress"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                      Nftxpress
                      </span>
                    </h3>
                  </div>
                  <div className="just d-flex ">
                    <button className="btt seecase" onClick={() => (
                  history("/Nftxpress"),
                  window.scrollTo({ top: 0, behavior: "instant" })
                )}>
                      <GoArrowUpRight /> See case{" "}
                    </button>
                  </div>
                </div>
              </>
            </SwiperSlide>

            <SwiperSlide className="boost">
              <>
                <div className="maindasda">
                  <div className="mainimg det2" onClick={() => (
                        history("/ArchieScan"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                    <img src={nine} className="slidimg" alt="" />
                  </div>
                  <div className="a ">
                    <h3 className="text-start  just pt-5 m-0">
                      {" "}
                      <span className="det2" onClick={() => (
                        history("/ArchieScan"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                      ArchieScan
                      </span>
                    </h3>
                  </div>
                  <div className="just d-flex ">
                    <button className="btt seecase" onClick={() => (
                  history("/ArchieScan"),
                  window.scrollTo({ top: 0, behavior: "instant" })
                )}>
                      <GoArrowUpRight /> See case{" "}
                    </button>
                  </div>
                </div>
              </>
            </SwiperSlide>

            <SwiperSlide className="boost">
              <>
                <div className="maindasda">
                  <div className="mainimg det2" onClick={() => (
                        history("/ArchienekoNft"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                    <img src={ten} className="slidimg" alt="" />
                  </div>
                  <div className="a ">
                    <h3 className="text-start  just pt-5 m-0">
                      {" "}
                      <span className="det2" onClick={() => (
                        history("/ArchienekoNft"),
                        window.scrollTo({ top: 0, behavior: "instant" })
                      )}>
                      Archienekonft
                      </span>
                    </h3>
                  </div>
                  <div className="just d-flex ">
                    <button className="btt seecase" onClick={() => (
                  history("/ArchienekoNft"),
                  window.scrollTo({ top: 0, behavior: "instant" })
                )}>
                      <GoArrowUpRight /> See case{" "}
                    </button>
                  </div>
                </div>
              </>
            </SwiperSlide>

          </Swiper>
        </div>
      </div>
    </>
  );
}
