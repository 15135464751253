import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import sainft from "./assets/sainft_main.jpg";

function Sainft() {
  return (
    <div className="sEeCase">
      <div className="container-fluid">
        <div className="row CaSeeUproW">
          <div className="col-lg-12 px-0">
            <div className="titles">
              <h1 className="caseHeading">Sainft </h1>
            </div>

            <div className="media">
              <img src={sainft} alt="" className="w-100" />
            </div>
          </div>
        </div>
        <div className="row CaSeeUproW2">
          <div className="col-lg-5">
            <div className="LeFtTitlE">
              <h6>Overview</h6>
            </div>
          </div>

          <div className="col-lg-7 rtSdd">
            <h2 className="detail2TiTlE">Sainft</h2>

            <div className="mt-4">
              <p className="detAilPeRa">
                Create, Add, Sell your NFTs at this right marketplace where
                transactions are quick, safe, and secure. So, get started to
                enjoy the NFT ride.
                <ol>
                  <li>
                    Set up your wallet Once you’ve set up your wallet of choice,
                    connect it to OpenSea by clicking the NFT Marketplace in the
                    top right corner. Learn about the wallets we support.
                  </li>
                  <li>
                    Create your collectionClick Create and set up your
                    collection. Add social links, a description, profile &
                    banner images, and set a secondary sales fee.
                  </li>
                  <li>
                    Add your NFTsUpload your work (image, video, audio, or 3D
                    art), add a title and description, and customize your NFTs
                    with properties, stats, and unlockable content.
                  </li>
                  <li>
                    List them for saleChoose between auctions, fixed-price
                    listings, and declining-price listings. You choose how you
                    want to sell your NFTs!
                  </li>
                </ol>
              </p>
            </div>

            <div className="row servs">
              <div className="col-lg-4 liuPrr">
                <h6 className="clName">Client</h6>
                <p className="nme">Punk Panda</p>
              </div>
              <div className="col-lg-4 liuPrr fstUl">
                <h6 className="clName">Services</h6>
                <ul className="uppl">
                  <li className="uppli">Blockchain</li>
                  <li className="uppli">UI design</li>
                  <li className="uppli">Development</li>
                </ul>
              </div>
              <div className="col-lg-4 liuPrr fstUl">
                <h6 className="clName">Technologies</h6>
                <ul className="uppl">
                  <li className="uppli">React.js</li>
                  <li className="uppli">Node.js</li>
                  <li className="uppli">Solidity</li>
                </ul>
              </div>
            </div>

            <div className="liveew">
              <a
                href="https://sainftmarketkplace.xyz/"
                target="blank"
                className="text-decoration-none"
              >
                <button className="btn seELivE_butn">
                  <FiArrowUpRight className="fs-5" />
                  See live
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* <div className="row CaSeeUproW3">
          <div className="col-lg-6">
            <div className="dBordPic">
              <img
                src={qbb3rd}
                alt="#"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-lg-6 rtSdd2">
            <div className="dBordPic">
              <img
                src={qbb4th}
                alt="#"
                className="w-100"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Sainft;
