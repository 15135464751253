import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import nftxpress from "./assets/main_nft_xpress.jpg"

function Nftxpress() {
  return (
    <div className="sEeCase">
      <div className="container-fluid">
        <div className="row CaSeeUproW">
          <div className="col-lg-12 px-0">
            <div className="titles">
              <h1 className="caseHeading">Nftxpress</h1>
              <h1 className="caseHeading">
                Nftxpress DECENTRALIZED AFFILIATE PROGRAM
              </h1>
            </div>

            <div className="media">
              <img src={nftxpress} alt="" className="w-100" />
            </div>
          </div>
        </div>
        <div className="row CaSeeUproW2">
          <div className="col-lg-5">
            <div className="LeFtTitlE">
              <h6>Overview</h6>
            </div>
          </div>

          <div className="col-lg-7 rtSdd">
            <h2 className="detail2TiTlE">
              Nftxpress <br /> Nftxpress DECENTRALIZED AFFILIATE PROGRAM
            </h2>

            <div className="mt-4">
              <p className="detAilPeRa">
                NFT-Xpress is an affiliate marketing platform providing our
                community with early access to some of the most innovative NFT
                projects. Carefully selected NFT projects are then set within a
                decentralized autonomous organization where the smart contracts
                ensure the system is dependable, secure, and trustless. Early
                access ensures our investors see the ultimate financial gain.
              </p>
            </div>

            <div className="row servs">
              <div className="col-lg-4 liuPrr">
                <h6 className="clName">Client</h6>
                <p className="nme">Punk Panda</p>
              </div>
              <div className="col-lg-4 liuPrr fstUl">
                <h6 className="clName">Services</h6>
                <ul className="uppl">
                  <li className="uppli">Blockchain</li>
                  <li className="uppli">UI design</li>
                  <li className="uppli">Development</li>
                </ul>
              </div>
              <div className="col-lg-4 liuPrr fstUl">
                <h6 className="clName">Technologies</h6>
                <ul className="uppl">
                  <li className="uppli">React.js</li>
                  <li className="uppli">Node.js</li>
                  <li className="uppli">Solidity</li>
                </ul>
              </div>
            </div>

            <div className="liveew">
              <a
                href="https://nftxpress.club/"
                target="blank"
                className="text-decoration-none"
              >
                <button className="btn seELivE_butn">
                  <FiArrowUpRight className="fs-5" />
                  See live
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* <div className="row CaSeeUproW3">
          <div className="col-lg-6">
            <div className="dBordPic">
              <img
                src={qbb3rd}
                alt="#"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-lg-6 rtSdd2">
            <div className="dBordPic">
              <img
                src={qbb4th}
                alt="#"
                className="w-100"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Nftxpress;
