import React from "react";
import { GoArrowUpRight } from "react-icons/go";

function DetailForPortfolio(props) {
  return (
    <div>
      <div className="maindasda upPerHvR">
        <div className="mainimg det2 porfrdss">
          <img src={props.CardImg} className="slidimg portfCrdPic" alt="" />
        </div>
        <div className="pfCrdBT mt-4">
          <div className="a ">
            <h3 className="text-start  just m-0">
              <span className="det2 cardpfTitle">
                {props.Title1} <br /> {props.Title2}
              </span>
            </h3>
          </div>
          <div className="just d-flex ">
            <button className="btt seecase">
              <GoArrowUpRight /> See case{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailForPortfolio;
