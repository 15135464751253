import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import bookOfG from "./assets/boo_of_gates_main.jpg";

function Case_detail3() {
  return (
    <div className="sEeCase">
      <div className="container-fluid">
        <div className="row CaSeeUproW">
          <div className="col-lg-12 px-0">
            <div className="titles">
              <h1 className="caseHeading">Book of Gates NFT </h1>
            </div>

            <div className="media">
              <img src={bookOfG} alt="" className="w-100" />
            </div>
          </div>
        </div>
        <div className="row CaSeeUproW2">
          <div className="col-lg-5">
            <div className="LeFtTitlE">
              <h6>Overview</h6>
            </div>
          </div>

          <div className="col-lg-7 rtSdd">
            <h2 className="detail2TiTlE">Book of Gates NFT</h2>

            <div className="mt-4">
              <p className="detAilPeRa">
                The Book of Gates is a Graphic Novel and Trading Game of 10,008
                Character NFTs on Ethereum. Join the Treasure Hunt and mint a
                key that could unlock over 250 Eth hiding in chests at every
                Gate! Mystery, adventure and high floor prices await! The Book
                of Gates Character NFTs derive from over 1.2 million attribute
                combinations from the stunning universe of the graphic novel.
                Each NFT will be a unique, randomly programed cell containing.
              </p>
            </div>

            <div className="row servs">
              <div className="col-lg-4 liuPrr">
                <h6 className="clName">Client</h6>
                <p className="nme">Punk Panda</p>
              </div>
              <div className="col-lg-4 liuPrr fstUl">
                <h6 className="clName">Services</h6>
                <ul className="uppl">
                  <li className="uppli">Blockchain</li>
                  <li className="uppli">UI design</li>
                  <li className="uppli">Development</li>
                </ul>
              </div>
              <div className="col-lg-4 liuPrr fstUl">
                <h6 className="clName">Technologies</h6>
                <ul className="uppl">
                  <li className="uppli">React.js</li>
                  <li className="uppli">Node.js</li>
                  <li className="uppli">Solidity</li>
                </ul>
              </div>
            </div>

            <div className="liveew">
              <a
                href="https://bookofgatesofficial.com/"
                target="blank"
                className="text-decoration-none"
              >
                <button className="btn seELivE_butn">
                  <FiArrowUpRight className="fs-5" />
                  See live
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* <div className="row CaSeeUproW3">
          <div className="col-lg-6">
            <div className="dBordPic">
              <img
                src={qbb3rd}
                alt="#"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-lg-6 rtSdd2">
            <div className="dBordPic">
              <img
                src={qbb4th}
                alt="#"
                className="w-100"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Case_detail3;
