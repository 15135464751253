import React from 'react'
import "./TechnologyStack_type.css"

function Others() {
  return (
    <div>
        <div className="row justify-content-evenly " style={{marginTop:"4rem"}}>
      <div className="col-lg-5">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/webp-express/webp-images/uploads/2023/05/image-48.png.webp" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>IPFS</h5>
        </div>
      </div>

      <div className="col-lg-5 meg2">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/MetaMask-Fox-Icon-in-Flat-Style-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Metamask</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/opensea-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>OpenSea API</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/coinbase-logo-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Coinbase wallet</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/binance-logo-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Binance API</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/Trust-Wallet-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Trust wallet</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/pancakeswap-cake-logo-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Pancakeswap</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/WalletConnect-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>WalletConnect</h5>
        </div>
      </div>


      </div>
    </div>
  )
}

export default Others
