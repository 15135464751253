import React from "react";
import "./Portfolio.css";
import DetailForPortfolio from "../DetailForPortfolio/DetailForPortfolio";
import one from "../assets/quest.jpg";
import b from "../assets/archieswap.jpg";
import c from "../assets/bookofgat2.jpg";
import d from "../assets/archie_stake.jpg";
import e from "../assets/sanit.jpg";
import sixth from "../assets/gulidqb.jpg";
import siven from "../assets/main_wireswap.jpg";
import eight from "../assets/nftxpressNew.jpg";
import nine from "../assets/archiscan.jpg";
import ten from "../assets/archieneko.jpg";
import { useNavigate } from "react-router-dom";

function Portfolio() {
  const history = useNavigate();
  return (
    <div className="portfolioMain">
      <div className="container-fluid">
        <h1 className="portsecTit">Explore our projects</h1>
        <div className="row portRoW">
          <div className="col-lg-6 lowerporCard2" onClick={() => (
              history("/Case_detail"),
              window.scrollTo({ top: 0, behavior: "instant" })
            )}>
            <DetailForPortfolio
              CardImg={one}
              Title1="Blockchain Gaming"
              Title2="Join QB Quest!"
            />
          </div>

          <div className="col-lg-6 lowerporCard2 lee" onClick={() => (
              history("/Case_detail2"),
              window.scrollTo({ top: 0, behavior: "instant" })
            )}>
            <DetailForPortfolio CardImg={b} Title1="ARCHIESWAP" />
          </div>

          <div className="col-lg-6  lowerporCard" onClick={() => (
              history("/Case_detail3"),
              window.scrollTo({ top: 0, behavior: "instant" })
            )}>
            <DetailForPortfolio CardImg={c} Title1="Book of Gates NFT" />
          </div>

          <div className="col-lg-6  lowerporCard" onClick={() => (
              history("/ArccoinARC"),
              window.scrollTo({ top: 0, behavior: "instant" })
            )}>
            <DetailForPortfolio CardImg={d} Title1="Arccoin(ARC)" />
          </div>

          <div className="col-lg-6  lowerporCard" onClick={() => (
              history("/Sainft"),
              window.scrollTo({ top: 0, behavior: "instant" })
            )}>
            <DetailForPortfolio CardImg={e} Title1="Sainft" />
          </div>

          <div className="col-lg-6  lowerporCard" onClick={() => (
              history("/GuildQbQuest"),
              window.scrollTo({ top: 0, behavior: "instant" })
            )}>
            <DetailForPortfolio
              CardImg={sixth}
              Title1="Blockchain Gaming"
              Title2="Guild QB [Quest & Battle]"
            />
          </div>

          <div className="col-lg-6  lowerporCard" onClick={() => (
              history("/Wireswap"),
              window.scrollTo({ top: 0, behavior: "instant" })
            )}>
            <DetailForPortfolio CardImg={siven} Title1="Wireswap Protocol" />
          </div>

          <div className="col-lg-6  lowerporCard" onClick={() => (
              history("/Nftxpress"),
              window.scrollTo({ top: 0, behavior: "instant" })
            )}>
            <DetailForPortfolio CardImg={eight} Title1="Nftxpress" />
          </div>

          <div className="col-lg-6  lowerporCard" onClick={() => (
              history("/ArchieScan"),
              window.scrollTo({ top: 0, behavior: "instant" })
            )}>
            <DetailForPortfolio CardImg={nine} Title1="ArchieScan" />
          </div>

          <div className="col-lg-6  lowerporCard" onClick={() => (
              history("/ArchienekoNft"),
              window.scrollTo({ top: 0, behavior: "instant" })
            )}>
            <DetailForPortfolio CardImg={ten} Title1="Archienekonft" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
