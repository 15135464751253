import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Touch_Modal.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { BsWhatsapp } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";
import emailjs from '@emailjs/browser';

function Touch_Modal(props) {

  const box = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
   
      emailjs.sendForm("service_pgg903e","template_0bnu8g9",box.current,"L7haAOkPwYMuAmrVd")
        .then((result) => {
            console.log(result.text);
            // alert("Message send successfully")
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    };


  return (
    <Modal
      {...props}
      dialogClassName="modal-90w"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center gap-4">
            <div className="col-lg-4 bglft py-5 px-3">
              <div className="left">
                <div className="section_titl">
                  {" "}
                  Do you need help bringing an idea into fruition?{" "}
                </div>
                <div className="mt-5 maillayer">
                  <a href="#" className="line-animation email ">
                    {" "}
                    cto@betterlogics.tech
                  </a>
                  <a href="#" onClick={() =>  navigator.clipboard.writeText('cto@betterlogics.tech')}><img
                    src="https://cdn.phenomenonstudio.com/wp-content/themes/phenomenon/img/copy.svg"
                    alt="#"
                    className="copylogo"
                  /></a>
                </div>

                <div className="social mt-5">
                  <a
                    href="https://wa.me/+923340691173"
                    target="blank"
                    className="tele"
                  >
                    <div className="whatsapp aps aaps1">
                      <div className="text-white d-flex align-items-center gap-3">
                        <BsWhatsapp className="whats" />
                        WhatsApp
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://t.me/Ethereum_007"
                    target="blank"
                    className="tele"
                  >
                    {" "}
                    <div className="telegram aps aaps2">
                      <div className="text-white d-flex align-items-center gap-3">
                        <FaTelegramPlane className="whats" />
                        Telegram
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-7 bglft">
              <div className="right px-4 mt-4">
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "100%" },
                  }}
                  noValidate
                  autoComplete="off"
                  ref={box}
                  onSubmit={sendEmail}
                >
                  <TextField
                    id="standard-basic"
                    type="text"
                    name="Username"
                    label="Your Name"
                    variant="standard"
                    className="text-white"
                  />

                  <TextField
                    id="standard-basic"
                    type="email"
                    name="Useremail"
                    label="Your Email"
                    variant="standard"
                    className="text-white mt-3"
                  />

                  <TextField
                    id="standard-basic"
                    type="text"
                    name="message"
                    label="Tell us about your project"
                    variant="standard"
                    className="text-white mt-4"
                  />

                  {/* <div className="mt-5">
                    <label htmlFor="attach_1" className="lbl">
                      <input type="file" id="attach_1" className="put" />
                      <img
                        src="https://cdn.phenomenonstudio.com/wp-content/themes/phenomenon/img/sk.svg"
                        alt=""
                        style={{ cursor: "pointer" }}
                      />
                      <span className="atchtitle">Attach file</span>
                    </label>
                  </div> */}

                  <div className="warning mt-5">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        class="form-check-label text-white"
                        for="flexCheckDefault"
                      >
                        I agree with your{" "}
                        <a href="" className="ppcy">
                          privacy policy
                        </a>
                      </label>
                    </div>
                    <button className="btn suub" type="submit" value="Send">
                      <span className="d-flex align-items-center gap-1">
                        <FiArrowUpRight className="fs-5" />
                        Submit
                      </span>
                    </button>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

// function Touch_Modal() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <div>
//       <>
//         <Button variant="primary" onClick={() => setModalShow(true)}>
//           Launch vertically centered modal
//         </Button>

//         <Touch_Modal
//           show={modalShow}
//           onHide={() => setModalShow(false)}
//         />
//       </>
//     </div>
//   );
// }

export default Touch_Modal;
