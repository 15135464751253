import React from "react";
import "./Footer.css";
import { FaTelegramPlane } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import twitterLogo from "../assets/Twitter-X-Logo.png";

function Footer() {
  return (
    <div className="fooTrMain">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 py-4">
            <img
              src="https://cdn.phenomenonstudio.com/wp-content/themes/phenomenon/img/star-full.svg"
              alt="#"
              className="flvr"
            />
            <div className="sclmida">
              <div className="uson"> Follow us on socials</div>
              <div className="socs_warp">
                <div className="socs">
                  <a
                    href="https://www.linkedin.com/in/better-logics-94354b237/"
                    target="blank"
                    className="socsinner socbg3"
                  >
                    <img
                      src="https://cdn.phenomenonstudio.com/wp-content/themes/phenomenon/img/s6.svg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/better_logics/"
                    target="blank"
                    className="socsinner socbg2"
                  >
                    <img
                      src="https://cdn.phenomenonstudio.com/wp-content/themes/phenomenon/img/s4.svg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://t.me/Ethereum_007"
                    target="blank"
                    className="socsinner socbg3"
                  >
                    <FaTelegramPlane className="fateli" />
                  </a>
                  <a
                    href="https://twitter.com/better_logics?t=M5kmp_mM-dCpenIS7GiJJw&s=09"
                    target="blank"
                    className="socsinner socbg4"
                  >
                    <img src={twitterLogo} alt="" className="w-75" />
                  </a>
                  <a href="#" target="blank" className="socsinner socbg5">
                    <FaDiscord className="fateli" />
                  </a>
                </div>
              </div>
              <div className="ritg">
                <p className="mb-0 cmpny">Better logics &copy; 2019</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
