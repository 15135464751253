import React from "react";
import "./First_sec.css";
import rightbx from "../assets/dsadasd.png";
import { BsArrowRightShort } from "react-icons/bs";
import Touch_Modal from "../Touch_Modal/Touch_Modal";

function First_sec() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <div className="contaner fstsc">
        <div className="row bdsmain">
          <div className="col-lg-7">
            <div className="heading">
              <h1 className="title">
                <div className="largScreen">
                  <span className="blok">Blockchain</span>
                  <span className="blockchain">Development</span>
                  <span className="servis">Services</span>
                </div>

                <div className="smallSCr">
                  <div className="smalblchN">
                    {" "}
                    Block- <br /> chain
                  </div>
                  <div className="blockchain">
                    <span>
                      Develop- <br /> ment
                    </span>
                  </div>
                  <div className="smalblchN">Services</div>
                </div>
              </h1>
            </div>
            <div className="hlpUpper mt-5">
              <div className="txt">
                <p className="mb-0">
                  We help unlock the potential of blockchain technology by
                  developing smart, powerful, and efficient applications
                </p>
              </div>
              <a
                onClick={() => setModalShow(true)}
                href="#"
                className="circle-btn lim"
              >
                <span>
                  {" "}
                  <BsArrowRightShort className="fs-3 me-1" /> Let’s Talk{" "}
                </span>
              </a>
              <Touch_Modal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
            </div>
          </div>
          <div className="col-lg-4 ritimg">
            <div>
              <img src={rightbx} alt="#" className="boxblok" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default First_sec;
